<template>
  <div id="contact">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>
            {{ $t('menu.contactUs') }}
          </h2>
          <p></p>
        </div>
        <div class="main">
          <p class="title">{{ $t('menu.contactUs') }}</p>
          <div class="info">
            <div>
              <div class="background"><img src="@/assets/images/contactUs/icons-email@3x.png" alt /></div>
              <span>{{ email }}</span>
            </div>
            <div>
              <div class="background"><img src="@/assets/images/contactUs/icons-phone@3x.png" alt /></div>
              <span>{{ phone }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const regulator = this.$store.state.common.regulator;
    return {
      email: this.$config.info.getEmail(regulator),
      phone: this.$config.info.getPhone(regulator)
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/contactUs.scss';
</style>
